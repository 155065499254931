import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

const ErrorPage = () => {
  return (
    <Layout>
      <Seo title="404" />
      <h1>Ops...Nothing here</h1>
      <Link to="/">Back home</Link>
    </Layout>
  )
}

export default ErrorPage
